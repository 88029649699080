import React from "react";

function App() {
  return (
    <div className="App text-center flex justify-center h-screen items-center">
      <div className={"space-y-32"}>
        <div>
          <header>
            <h1 className={"font-headline text-2xl md:text-3xl"}>Alex Faust</h1>
          </header>
          <div className={"flex gap-12 md:gap-20 justify-center"}>
            <div className={""}>Branding</div>
            <div className={""}>UI/UX</div>
            <div className={""}>Development</div>
          </div>
        </div>
        <footer>
          <h2 className={"font-headline text-center text-xl md:text-3xl"}>
            <a href={"mailto: moin@alexfaust.de"}>moin@alexfaust.de</a>
          </h2>
        </footer>
      </div>
    </div>
  );
}

export default App;
